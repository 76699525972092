import { MessageBox } from "../modals/message-box";

export function createDiv(className? :string, parent? :HTMLElement) {
	var d = document.createElement("div");
	if(typeof className === "string" && className) d.className = className;
	if(typeof parent === "object" && typeof parent.appendChild === "function") parent.appendChild(d);
	return d;
}
export function createHtml<K extends keyof HTMLElementTagNameMap>(elem : K, className? :string, parent? :HTMLElement) :HTMLElementTagNameMap[K] {
	var e = document.createElement(elem);
	if(typeof className === "string" && className) e.className = className;
	if(isHtmlElement(parent) === true) parent.appendChild(e);
	return e;
}
export function numberWithSpace(n: number, percent?: number) {
	if (typeof n !== "number" || isNaN(n) ) return "";
    n = Math.round(n * 10000) / 10000;
	var a = [];
	percent != undefined ? a = n.toFixed(percent).split(".") : a = n.toString().split(".");
	a.length == 1 ? a.push("") : a[1] = "." + a[1];
	a[0] = a[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	return a[0] + a[1];
}
export function isHtmlElement(obj :any) {
	if (obj == null) return false;
	return (typeof obj === "object") && (obj.nodeType===1) && (typeof obj.style === "object") && (typeof obj.ownerDocument ==="object");
}
export function remove(_elem :HTMLElement) {
	if (_elem !== null && typeof _elem === "object" && typeof _elem.parentNode === "object" && _elem.parentNode !== null) {
		_elem.parentNode.removeChild(_elem);
	}
}
export function isChildOf(child :HTMLElement, parent :HTMLElement) {
	if (child == parent) return true;
	var node = child.parentNode;
	while (node != null) {
		if (node == parent) {
			return true;
		}
		node = node.parentNode;
	}
	return false;
}
export function newEvent(name :string, obj? :any, dispatcher:any = null) {
	if (!dispatcher || !dispatcher.dispatchEvent) dispatcher = window;
	if (typeof window.CustomEvent !== "function") {
		var evt = document.createEvent( 'CustomEvent' );
		evt.initCustomEvent( name, true, false, obj );
		dispatcher.dispatchEvent(evt);
	}else {
		var myEvent = new CustomEvent(name, {
			detail: obj,
			bubbles: true,
			cancelable: false
		});
		dispatcher.dispatchEvent(myEvent);
	}
}
export function waitAsync(ms: number = 0) {
	return new Promise<Boolean>((resolve) => {
		setTimeout(() => {
			resolve(true)
		}, ms);
	})
}

export function addDragListner(el: HTMLElement,
	OnStart: (target: HTMLElement, evt: PointerEvent) => any,
	OnMove: (target: HTMLElement, evt: PointerEvent, dx: number, dy: number) => any,
	OnEnd: (target: HTMLElement, evt: PointerEvent) => any,
) {
	let startX = null;
	let startY = null;
	const onMove = (evt: PointerEvent) => {
		evt.preventDefault();
		let dx = evt.pageX - startX;
		let dy = evt.pageY - startY;
		OnMove(el, evt, dx, dy);
	}
	const onEnd = (evt: PointerEvent) => {
		evt.preventDefault();
		window.removeEventListener("pointermove", onMove);
		window.removeEventListener("pointerup", onEnd);
		window.removeEventListener("pointercancel", onEnd);
		startX = startY = null;
		OnEnd(el, evt);
	}
	el.addEventListener("pointerdown", (evt) => {
		if (startX == null) {
			OnStart(el, evt);
			evt.preventDefault();
			startX = evt.pageX;
			startY = evt.pageY;
			window.addEventListener("pointermove", onMove);
			window.addEventListener("pointerup", onEnd);
			window.addEventListener("pointercancel", onEnd);
		}
	});
}


export async function fetchAction(url: string, method = "POST", body: any) {
	try {
		let resp = await fetch(url, {
			method: method as any,
			headers: {
				"Content-Type": "application/json",
			},
			body: (body ? JSON.stringify(body) : undefined),
		});
		let jsonResp = await resp.json();
		if (jsonResp.res !== "OK") throw jsonResp;
		return jsonResp.data || true;
	}catch(err) {
		throw err;
	}
}

export function errorHandler(err :any) {
    if (err && err.res && (err.message || err.msg)) {
        return MessageBox.Show((err.message || err.msg).split("\n").join('<br/>'), {
            titleText: "Figyelem!",
        });
    }
    if (err.code) {
        return MessageBox.Show(err.code.split("\n").join('<br/>'), {
            titleText: "Hiba történt!",
        });;
    }
    console.log(err);
    return MessageBox.Show("Ismeretlen hiba történt! Kérjük próbálja meg később!", {
        titleText: "Hiba történt!",
    });
};

//#endregion


//#region VIEWPORT
export function getScrollbarWidth() {
	try {
		if (!document || !document.body) return 0;
		var div = document.createElement("div");
		div.style.cssText = "position:fixed;left:0;right:0;top:0;bottom:0;";
		div.style.overflow = "hidden";
		document.body.appendChild(div);
		var w1 = div.clientWidth;
		div.style.overflow = "scroll";
		var w2 = div.clientWidth;
		document.body.removeChild(div);
		return Math.abs(w1-w2);
	} catch(err) { return 0; }
}
export function viewportSize() {
	var test = document.createElement( "div" );
	test.style.cssText = "position: fixed;top: 0;left: 0;bottom: 0;right: 0;";
	document.documentElement.insertBefore( test, document.documentElement.firstChild );
	var dims = { width: Math.floor(test.offsetWidth||0), height: Math.floor(test.offsetHeight|0) };
	document.documentElement.removeChild( test );
	return dims;
}
export function waitElemVisible(elem: HTMLElement, totalVisible: boolean = true, callback: (el: HTMLElement) => void) {
	const timeout = 200;
	const checkFn = () => {
		try {
			if (elem == null || typeof elem.getBoundingClientRect !== "function") return;
			const rect = elem.getBoundingClientRect();
			if (rect.top == 0 && rect.height == 0 && rect.width == 0 && rect.left == 0) return;
			const bottom = rect.top + rect.height;
			const wh = window.innerHeight;
			const isBottom = (bottom > 0 && bottom < wh);
			const isTop = (rect.top > 0 && rect.top < wh);
			if (totalVisible && (isBottom && isTop)) {
				try {
					callback(elem);
				}catch(err) { console.log(err) }
				return;
			} else if (!totalVisible && (isBottom || isTop)) {
				try {
					callback(elem);
				}catch(err) { console.log(err) }
				return;
			}
			setTimeout(checkFn, timeout);
		}catch(err) {
	
		}
	}
	checkFn();
}
//#endregion


//#region OTHER
export function isNumeric(n :any) {
	return (typeof n == 'number' && !isNaN(n) && isFinite(n));
}
export function generateID(_length? :number, _sepa? :string, _sepaCount? :number) {
	if (!isNumeric(_length)) _length = 16;
	if (!_sepa) _sepa = "";
	if (!_sepaCount) _sepaCount = 4;
	if (_length<_sepaCount) _length = _sepaCount;
	var keys = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
	var result = "";
	for (var i = 0; i<_length; i++) {
		result += keys[Math.floor(Math.random()*keys.length)];
		if ((i+1)%_sepaCount==0 && i>0 && i<_length-1) result+=_sepa;
	}
	return result;
}
//#endregion