import { Modal } from "./modal";
import { createDiv } from './../routines/routines';

export interface MessageBoxOptions {
    titleText?: string;
    closeText?: string;
}

export class MessageBox {
    constructor() { }
    static Show(text :string, options?: MessageBoxOptions, callback? :() => void) {
        if (!options) options = {};
        if (!options.titleText) options.titleText = "Üzenet";
        if (!options.closeText) options.closeText = "OK";
        const modal = new Modal();
        modal.container.classList.add("message-box");
        modal.header.innerHTML = options.titleText;
        const textContent = createDiv("message-box-text", modal.content);
        textContent.innerHTML = text;
        const btn = createDiv("btn important", modal.footer);
        btn.innerHTML = options.closeText;
        modal.show();
        const close = () => {
            if (typeof callback == "function") {
                try { callback(); } catch(err) { }
            }
            modal.hide();
        }
        btn.addEventListener("click", close);
        modal.background.addEventListener("click", close);
    }
    static ShowAsync = (text :string, options?: MessageBoxOptions) :Promise<boolean> => {
        return new Promise((resolve) => {
            MessageBox.Show(text, options, () => {
                resolve(true);
            });
        })
    }
}