import { Modal } from "./modals/modal";
import { createHtml, errorHandler } from "./routines/routines";

export class Header {
    public static instance: Header = null;
    LoginForm = () => {
        let modal = new Modal();
        modal.container.classList.add("login-modal");
        let modalTitle = createHtml("h4", "", modal.header);
        modalTitle.innerHTML = "Bejelentkezés";
        let closeBtn = createHtml("div", "close", modal.header);
        closeBtn.addEventListener("click", modal.hide);
        let loginForm = createHtml("form", "login-form", modal.content)
        let emailLine = createHtml("div", "input-line", loginForm);
        let emailLabel = createHtml("label", "", emailLine);
        emailLabel.innerHTML = "Email";
        let emailInput = createHtml("input", "", emailLine);
        emailInput.type = "email";

        let passLine = createHtml("div", "input-line", loginForm);
        let passLabel = createHtml("label", "", passLine);
        passLabel.innerHTML = "Password";
        let passInput = createHtml("input", "", passLine);
        passInput.type = "password";

        let space = createHtml("div", "margin-vertical", loginForm);
        let btns = createHtml("div", "btbs align-center", loginForm);

        let submit = createHtml("button", "", btns);
        let slashSpan = createHtml("span", "slash", btns);
        slashSpan.innerHTML = "/";
        let signIn = createHtml("button", "important", btns);
        submit.type = "submit";
        submit.innerHTML = "Bejelentkezés";
        signIn.innerHTML = "Regisztráció";

        signIn.addEventListener("click", (evt) => {
            evt.preventDefault();
            modal.hide();
            this.SignUpForm();
        })

        loginForm.addEventListener("submit", async (evt) => {
            evt.preventDefault();
            try {
                let resp = await fetch("/login", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        email: emailInput.value,
                        password: passInput.value,
                    }),
                });
                let jsonResp = await resp.json();
                if (jsonResp.res !== "OK") throw jsonResp;
                location.reload();
            }catch(err) {
                errorHandler(err);
            }
        });


        modal.show();
    }

    SignUpForm = () => {
        const createInputLine = (parent: HTMLElement, name: string, type: string, inputName: string) => {
            let inputLine = createHtml("div", "input-line", parent);
            let label = createHtml("label", "", inputLine);
            label.innerHTML = name;
            let input = createHtml("input", "", inputLine);
            input.name = inputName;
            input.type = type;
            if (type == "password") {
                input.setAttribute("autocomplete", "new-password");
            }
            return input;
        };
        let modal = new Modal();
        modal.container.classList.add("signup-modal");
        let modalTitle = createHtml("h4", "", modal.header);
        modalTitle.innerHTML = "Bejelentkezés";
        let closeBtn = createHtml("div", "close", modal.header);
        closeBtn.addEventListener("click", modal.hide);
        let signUpForm = createHtml("form", "login-form", modal.content);
        //cartAcceptBtn.classsignUpForm.setAttribute("autocomplete", "off");

        createHtml("h4", "align-center", signUpForm).innerHTML = "Alapadatok:";
        
        let inputName = createInputLine(signUpForm, "Teljes Név", "text", "name");
        let inputEmail = createInputLine(signUpForm, "Email cím", "email", "email");
        let inputPhone = createInputLine(signUpForm, "Telefonszám", "phone", "phone");
        let inputPass = createInputLine(signUpForm, "Jelszó", "password", "new-password");
        let inputPass2 = createInputLine(signUpForm, "Jelszó megerősítése", "password", "new-password-confirm");

        createHtml("h4", "align-center", signUpForm).innerHTML = "Számlázási cím:";

        let inputZip = createInputLine(signUpForm, "Irányítószám", "text", "zip");
        let inputCity = createInputLine(signUpForm, "Város", "text", "city");
        let inputAddress = createInputLine(signUpForm, "Utca, házszám","text", "address");

        
        let space = createHtml("div", "margin-vertical", signUpForm);
        let btns = createHtml("div", "btbs align-center", signUpForm);

        let submit = createHtml("button", "", btns);
        submit.type = "submit";
        submit.innerHTML = "Regisztráció";

        signUpForm.addEventListener("submit", async (evt) => {
            evt.preventDefault();
            try {
                let resp = await fetch("/signup", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        name: inputName.value,
                        email: inputEmail.value.trim(),
                        phone: inputPhone.value.trim(),
                        password: inputPass.value,
                        password_confirm: inputPass2.value,
                        address: {
                            zip: inputZip.value.trim(),
                            city: inputCity.value.trim(),
                            address: inputAddress.value.trim(),
                        },
                    }),
                });
                let jsonResp = await resp.json();
                if (jsonResp.res !== "OK") throw jsonResp;
                location.reload();
            }catch(err) {
                errorHandler(err);
            }
        });


        modal.show();
    }

    Logout = async () => {
        try {
            let resp = await fetch("/logout", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                }),
            });
            let jsonResp = await resp.json();
            if (jsonResp.res !== "OK") throw jsonResp;
            location.reload();
        }catch(err) {
            errorHandler(err);
        }
    }

    constructor() {
        Header.instance = this;
        let header = document.querySelector(".header");
        if (!header) return;
        let loginBtn = header.querySelector(".btn.login");
        let signUpBtn = header.querySelector(".btn.signin");
        let logoutBtn = header.querySelector(".btn.logout");
        if (loginBtn) loginBtn.addEventListener("click", this.LoginForm);
        if (signUpBtn) signUpBtn.addEventListener("click", this.SignUpForm);
        if (logoutBtn) logoutBtn.addEventListener("click", this.Logout);
    }
}