export const Maths = {
    clamp: (value: number, min: number, max: number) => {
        if (max<min) {
            var t = max;
            max = min;
            min = t;
        }
        return Math.max(min,Math.min(max,value));
    },
    clamp01: (value: number) => {
        return Math.max(0,Math.min(1,value));
    },
    lerp: (a: number, b: number, t: number) => {
        return (b - a) * t + a;
    },
    inversLerp: (a: number, b: number, p: number) => {
        if ((b-a)==0) return 0;
        return (p-a)/(b-a);
    },
    radToDeg: (rad: number) => {
        return rad * 180 / Math.PI
    },
    degToRad: (deg: number) => {
        return deg * Math.PI / 180;
    },
    randomRange: (min: number, max: number) => {
        return Math.random()*(max-min)+min;
    },
    randomRangeInt: (min: number, max: number) => {
        return Math.floor(Math.random()*(max-min)+min);
    },
}
