import { Header } from "./header";
import { MessageBox } from "./modals/message-box";
import { Modal } from "./modals/modal";
import { Anim } from "./routines/anim";
import { Easing } from "./routines/easing";
import { Maths } from "./routines/math";
import { createHtml, errorHandler, fetchAction, newEvent } from "./routines/routines";

export type CartItemStatus = "ACTIVE" | "DELETED";
export interface CartItemRaw {
    id: number;
    name: string;
    status: CartItemStatus;
    cart_id: number;
    ticket_id: number;
    amount: number;
    price: number;
}

export type CartsStatus = "ACTIVE" | "DELETED";
export interface CartClient {
    name: string;
    address: Address;
    email: string;
    phone: string;
}
export type CartPaymentMode = "CARD" | "BANK";
export interface CartPayment {
    mode: CartPaymentMode;
}
export interface CartPrices {
    net: number;
}
export interface CartsRaw {
    id?: number;
    status?: CartsStatus;
    customer_id: number;
    session_id: string;
    client: CartClient;
    payment: CartPayment;
    date_create: Date;
    items?: CartItemRaw[];
    prices?: CartPrices;
}

export class CartItemLine {
    constructor(it: CartItemRaw, i: number, body: HTMLElement) {
        let tr = createHtml("tr", "", body);
        let td = createHtml("td", "center", tr);
        td.innerHTML = (i + 1) + "";

        td = createHtml("td", "nowrap", tr);
        td.innerHTML = it.name;

        td = createHtml("td", "cart-amount-editor", tr);
        let minus = createHtml("button", "icon", td);
        let amountInput = createHtml("input", "", td);
        let plus = createHtml("button", "icon", td);
        minus.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>';
        plus.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z"/></svg>';
        amountInput.type = "number";
        amountInput.value = it.amount + "";
        amountInput.addEventListener("change", async () => {
            let newValue = parseInt(amountInput.value);
            if (isNaN(newValue)) {
                amountInput.value = it.amount + "";
                return;
            }
            if (newValue <= 0) {
                newValue = 0;
                if (!confirm("Biztosan törölni szeretnéd ezt a tételt?")) {
                    amountInput.value = it.amount + "";
                    return;
                }
            }
            await Cart.setCartAmount(it.ticket_id, newValue);
        });
        minus.addEventListener("click", async () => {
            if (it.amount == 1) {
                if (!confirm("Biztosan törölni szeretnéd ezt a tételt?")) {
                    return;
                }
            }
            await Cart.setCartAmount(it.ticket_id, it.amount - 1);
        });
        plus.addEventListener("click", async () => {
            await Cart.setCartAmount(it.ticket_id, it.amount + 1);
        });

        td = createHtml("td", "", tr);
        td.innerHTML = it.price + "";

        td = createHtml("td", "", tr);
        td.innerHTML = Math.round(it.price * it.amount) + "";

        td = createHtml("td", "cart-amount-editor", tr);
        let delBtn = createHtml("button", "icon delete", td);
        delBtn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>';
        delBtn.addEventListener("click", async () => {
            if (!confirm("Biztosan törölni szeretnéd ezt a tételt?")) {
                return;
            }
            await Cart.setCartAmount(it.ticket_id, 0);
        });
    }
}

export class Cart {
    static addToCart = async (ticket_id: number, amount: number) => {
        let cart = await fetchAction("/cart/add", "POST", {
            ticket_id: ticket_id,
            amount: amount,
        });
        newEvent("CART_CHANGED", cart);
    }
    static setCartAmount = async (ticket_id: number, amount: number) => {
        let cart = await fetchAction("/cart/amount", "POST", {
            ticket_id: ticket_id,
            amount: amount,
        });
        newEvent("CART_CHANGED", cart);
    }

    refreshCartTable = (cart: CartsRaw) => {
        let body = document.querySelector(".cart-table-body") as HTMLElement;
        if (!body) return;
        body.innerHTML = "";
        for (let i = 0; i < cart?.items?.length; i++) {
            let it = cart.items[i];
            new CartItemLine(it, i, body);
        }
        if (!cart?.items?.length) {
            let tr = createHtml("tr", "", body);
            let td = createHtml("td", "align-center bold", tr);
            td.colSpan = 6;
            td.innerHTML = "A kosár üres!";
        }
    }
    refreshCheckoutTable = (cart: CartsRaw) => {
        let body = document.querySelector(".cart-table-checkout") as HTMLElement;
        if (!body) return;
        body.innerHTML = "";
        for (let i = 0; i < cart.items.length; i++) {
            let it = cart.items[i];
            let tr = createHtml("tr", "", body);
            let td = createHtml("td", "center", tr);
            td.innerHTML = (i + 1) + "";
    
            td = createHtml("td", "nowrap", tr);
            td.innerHTML = it.name;
    
            td = createHtml("td", "cart-amount-editor", tr);
            td.innerHTML = it.amount + "";
            
            td = createHtml("td", "", tr);
            td.innerHTML = it.price + "";
    
            td = createHtml("td", "", tr);
            td.innerHTML = Math.round(it.price * it.amount) + "";
        }
        let tr = createHtml("tr", "sum", body);
        let empty = createHtml("td", "", tr);
        empty.colSpan = 3;
        createHtml("td", "", tr).innerHTML = "Összesen";
        createHtml("td", "", tr).innerHTML = cart.prices.net + "";

    }
    addToCartEffect = (addToCartBtn: HTMLElement, target: HTMLElement) => {
        let cartEffect = createHtml("div", "add-to-cart-effect", document.body);
        cartEffect.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 479.16"><path fill="#C28F60" d="m0 431.82 366 47.34 139.66-90.53L507.59 44 184.93 0 0 83.91z"/><path fill="#AA7950" d="m507.59 44-142.87 89.47L0 83.91 184.93 0z"/><path fill="#D2A06D" d="m366.06 479.16-1.34-345.69L507.59 44 512 389.98z"/><path fill="#65472F" d="m249.76 8.84 105.69 14.41-151.29 90.4-.09 156.47-53.83-36.66-53.84 30.43 6.72-164.29z"/></svg>';
        new Anim(0.5, (t) => {
            let t2 = Easing.Sinusoidal.In(t);
            //t = Easing..In(t);

            let start = addToCartBtn.getBoundingClientRect();
            let end = target.getBoundingClientRect();
            let xFrom = start.left + (start.width / 2);
            let yFrom = start.top + (start.height / 2);
            let xTo = end.left + (end.width / 2);
            let yTo = end.top + (end.height / 2);
            let x = Math.round(Maths.lerp(xFrom, xTo, t));
            let y = Math.round(Maths.lerp(yFrom, yTo, t));
            let o = Math.round(Maths.lerp(1, 0, t2) * 100) / 100;
            let s = Math.round(Maths.lerp(1, 0.2, t2) * 100) / 100;
            cartEffect.style.transform = `translate(${x}px, ${y}px) scale(${s})`;
            cartEffect.style.opacity = o + "";
        });
        setTimeout(() => {
            cartEffect.parentNode.removeChild(cartEffect);
        }, 1300);
    }

    constructor() {
        if (document.querySelector(".page-checkout")) {
            new PageCheckout();
        }
        let headerCounter: HTMLElement = document.querySelector(".cart-btn .cart-count");

        let btns: HTMLElement[] = [].slice.call(document.querySelectorAll(".cart-btn"));
        for (let btn of btns) {
            let id = parseInt(btn.dataset.tid);
            if (!id) continue;
            btn.addEventListener("click", (evt) => {
                let target: HTMLElement = evt.currentTarget as any;
                let ticket_id = parseInt(target.dataset.tid);
                Cart.addToCart(ticket_id, 1);
                if (headerCounter) this.addToCartEffect(target, headerCounter);
            });
        }
        let cartAcceptBtn = document.querySelector("#cart-accept-btn");
        window.addEventListener("CART_CHANGED", (evt: any) => {
            let cart: CartsRaw = evt.detail;
            this.refreshCartTable(evt.detail);
            this.refreshCheckoutTable(evt.detail);
            if (headerCounter) {
                headerCounter.classList.toggle("visible", cart?.items?.length > 0);
                headerCounter.innerHTML = (cart?.items?.length || 0) + "";
            }
            if (cartAcceptBtn) cartAcceptBtn.classList.toggle("inactive", !Boolean(cart?.items?.length));
        });
        
        if (cartAcceptBtn) cartAcceptBtn.addEventListener("click", () => {
            if (!window.user_id) {
                Header.instance.LoginForm();
                return;
            }
            document.location = "/penztar";
        })
        this.Init();
    }
    Init = async () => {
        let cart = await fetchAction("/cart/get", "POST", {});
        if (cart) newEvent("CART_CHANGED", cart);
    }
}

class PageCheckout {
    nameInput: HTMLInputElement;
    emailInput: HTMLInputElement;
    phoneInput: HTMLInputElement;
    zipInput: HTMLInputElement;
    cityInput: HTMLInputElement;
    addressInput: HTMLInputElement;
    payRadioCard: HTMLInputElement;
    payRadioBank: HTMLInputElement;
    submitBtn: HTMLElement;

    constructor() {
        this.nameInput = document.getElementById("cart-input-name") as any;
        this.emailInput = document.getElementById("cart-input-email") as any;
        this.phoneInput = document.getElementById("cart-input-phone") as any;
        this.zipInput = document.getElementById("cart-input-zip") as any;
        this.cityInput = document.getElementById("cart-input-city") as any;
        this.addressInput = document.getElementById("cart-input-address") as any;
        this.payRadioCard = document.getElementById("cart-input-payment-card") as any;
        this.payRadioBank = document.getElementById("cart-input-payment-bank") as any;
        this.submitBtn = document.getElementById("send-order-btn");

        this.submitBtn.addEventListener("click", async () => {
            try {
                let res = await fetchAction("/cart/order", "POST", {
                    name: this.nameInput.value.trim(),
                    email: this.emailInput.value.trim(),
                    phone: this.phoneInput.value.trim(),
                    address: {
                        zip: this.zipInput.value.trim(),
                        city: this.cityInput.value.trim(),
                        address: this.addressInput.value.trim(),
                    },
                    paymethod: (this.payRadioCard.checked ? 'CARD' : this.payRadioBank.checked ? "BANK" : null),
                });
                console.log(res);
                location.href = res.redirect_url;
            }catch(err) {
                console.log(err);
                errorHandler(err);
            }
        });
    }
}