import { Cart } from "./cart";
import { Header } from "./header";

export class Main {
    public static Init() {
        console.log("START");
        new Header();
        new Cart();
    }
}
document.addEventListener("DOMContentLoaded", Main.Init);