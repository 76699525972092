import { createDiv } from './../routines/routines';
import { getScrollbarWidth } from '../routines/routines';

export class Modal {
    visible = false;
    container :HTMLDivElement;
    background: HTMLDivElement
    win: HTMLDivElement;
    header: HTMLDivElement;
    contentContainer: HTMLDivElement;
    content: HTMLDivElement;
    footer: HTMLDivElement;

    private static modalCount = 0;
    static fixedContentSelector = ".header, .message-card";
    static onModalShow() {
        if (this.modalCount == 0) {
            const scw = getScrollbarWidth();
            document.body.style.overflow = "hidden";
            document.body.style.paddingRight = scw + "px";
            const fixedContents = document.querySelectorAll(this.fixedContentSelector);
            for (let i = 0; i < fixedContents.length; i++) {
                const it :HTMLElement = fixedContents[i] as any;
                const style = getComputedStyle(it);
                if (style.position == "fixed") {
                    if (it.classList.contains("header")) {
                        it.style.paddingRight = scw + "px";
                    }else {
                        it.style.marginRight = scw + "px";
                    }
                }
            }
        }
        Modal.modalCount++;
    }
    static onModalHide() {
        Modal.modalCount--;
        if (Modal.modalCount < 0) Modal.modalCount = 0;
        if (Modal.modalCount == 0) {
            document.body.style.overflow = "";
            document.body.style.paddingRight = "";
            const fixedContents = document.querySelectorAll(this.fixedContentSelector);
            for (let i = 0; i < fixedContents.length; i++) {
                const it :HTMLElement = fixedContents[i] as any;
                it.style.marginRight = "";
                it.style.paddingRight = "";
            }
            
        }
    }

    constructor() {
        this.container = createDiv("modal-container", document.body);
        this.background = createDiv("modal-background", this.container);
        this.win = createDiv("modal-window", this.container);
        this.header = createDiv("modal-header", this.win);
        this.contentContainer = createDiv("modal-content-container", this.win);
        this.content = createDiv("modal-content", this.contentContainer);
        this.footer = createDiv("modal-footer", this.win);
    }
    show = () => {
        if (this.visible) return;
        this.visible = true;
        Modal.onModalShow();
        setTimeout(() => {
            this.container.classList.add("visible");
        }, 300);
    }
    hide = () => {
        if (!this.visible) return;
        this.container.classList.remove("visible");
        this.visible = false;
        Modal.onModalHide();
    }
}