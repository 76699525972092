export interface CallDelayResult {
    cancel?: boolean;
}

export function Anim(interval: number, cb: (t: number) => void) {
    let lastTime = new Date().getTime();
    let time = 0;
    const update = () => {
        const now = new Date().getTime();
        let delta = now - lastTime;
        if (delta > 300) delta = 300;
        if (delta < 1) delta = 1;
        lastTime = now;
        time += delta / 1000;
        if (time >= interval) {
            time = interval;
        } else {
            requestAnimationFrame(update);
        }
        let value = Math.round((time / interval) * 10000) / 10000;
        if (value >= 1) value = 1;
        try { cb(value); }catch(err) { console.log(err); }
    }
    update();
}